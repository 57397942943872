<template>
	<div class="CourseCenter">
		<!--头部-->
		<headers ref="header"></headers>
		<!-- 中间部分 -->
		<div class="CC_content">
			<router-view></router-view>
		</div>
		<!--尾部-->
		<footers></footers>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	import headers from "/src/views/header";//头部
	import footers from "../footer";//尾部
	export default {
		components: {
			headers: headers,
			footers:footers
		},
		data() {
			return {
				
			}
		},
		methods: {
			//调用登录弹窗
			Callloginpopup(){
				this.$refs.header.showLogin();
			}
		},
		created(){
		}
	}
</script>

<style>
	.CourseCenter{
		width: 100%;
		min-width: 1200px;
	}
	.CC_content{
		min-height: calc(100vh - 325px);
		background-color: #F4F5F9;
	}
</style>
